import { BaseUrl } from "./baseUrl";

export const addFaqApi = BaseUrl + "/insert-faq";
export const getFaqApi = BaseUrl + "/get-faq";
export const deleteFaqApi = BaseUrl + "/delete-faq";
export const updateFaqApi = BaseUrl + "/update-faq";

// ===============about us =========================//
export const addAboutUsApi = BaseUrl + "/insert-about-us";
export const getboutUsApi = BaseUrl + "/get-about-us";
export const deleteAboutUsApi = BaseUrl + "/delete-about-us";
export const updateAboutUsApi = BaseUrl + "/update-about-us";

// ===============news =========================//
export const addNewsApi = BaseUrl + "/insert-news";
export const getNewsApi = BaseUrl + "/get-news";
export const deleteNewsApi = BaseUrl + "/delete-news";
export const updateNewsApi = BaseUrl + "/update-news";

// ===============partner =========================//
export const addPartnerApi = BaseUrl + "/insert-partner";
export const getPartnerApi = BaseUrl + "/get-partner";
export const deletePartnerApi = BaseUrl + "/delete-partner";
export const updatePartnerApi = BaseUrl + "/update-partner";

// ===============content =========================//
export const addContentApi = BaseUrl + "/insert-content";
export const getContentApi = BaseUrl + "/get-content";
export const deleteContentApi = BaseUrl + "/delete-content";
export const updateContentApi = BaseUrl + "/update-content";
export const updateContentStatusApi = BaseUrl + "/update-content-status";


