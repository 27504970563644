import * as opsService from "./Ops";

import { BaseUrl } from "../Constent/baseUrl";

const loginAdmin = async (data) => {
  let result = await opsService.postdata(BaseUrl + "/login-admin", data);
  return result;
};
const getUserData = async ( token) => {
  console.log(token,"token");
  let result = await opsService.getData(
    BaseUrl + "/get-user-data",
  
    token
  );
  return result;
};

const updateStatus = async (data, token) => {
  let result = await opsService.postdata(
    BaseUrl + "/update-status",
    data,
    token
  );
  return result;
};
const getDashboardData = async ( token) => {

  let result = await opsService.getData(
    BaseUrl + "/dashboard-data",
  
    token
  );
  return result;
};


export { loginAdmin, getUserData, updateStatus,getDashboardData };
