import * as opsService from "./Ops";
import {
  addAboutUsApi,
  getboutUsApi,
  deleteAboutUsApi,
  updateAboutUsApi,
} from "../Constent/Api";

const addAboutUs = async (data, token) => {
  let result = await opsService.postdata(addAboutUsApi, data, token);
  return result;
};
const getAboutUsData = async (token) => {
  let result = await opsService.getData(getboutUsApi, token);
  return result;
};

const deleteAboutUs = async (data, token) => {
  let result = await opsService.postdata(deleteAboutUsApi, data, token);
  return result;
};
const updateAboutUs = async (data, token) => {
  let result = await opsService.postdata(updateAboutUsApi, data, token);
  return result;
};

export { addAboutUs, getAboutUsData, deleteAboutUs, updateAboutUs };
