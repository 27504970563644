import * as opsService from "./Ops";
import {
  addContentApi,
  getContentApi,
  deleteContentApi,
  updateContentApi,updateContentStatusApi
} from "../Constent/Api";

const addContent = async (data, token) => {
  let result = await opsService.postdata(addContentApi, data, token);
  return result;
};
const getContentData = async (token) => {
  let result = await opsService.getData(getContentApi, token);
  return result;
};

const deleteContent = async (data, token) => {
  let result = await opsService.postdata(deleteContentApi, data, token);
  return result;
};
const updateContent = async (data, token) => {
  let result = await opsService.postdata(updateContentApi, data, token);
  return result;
};
const updateContentStatus = async (data, token) => {
  let result = await opsService.postdata(updateContentStatusApi, data, token);
  return result;
};


export { addContent, getContentData, deleteContent, updateContent,updateContentStatus };
