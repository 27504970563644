import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { InputValid } from "../../validations/InputValid";
import { updateFaq } from "../../services/admin";
export const UpdateFaqModal = (props) => {
  const [question, setQuestion] = useState("");
  const [questionErr, setQuestionErr] = useState("");
  const [answer, setAnswer] = useState("");
  const [answerErr, setAnswerErr] = useState("");
  const [language, setLanguage] = useState("");
  const [languageErr, setLanguageErr] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    if (props.currentRecord) {
      setQuestion(props.currentRecord.question);
      setAnswer(props.currentRecord.answer);
      setLanguage(props.currentRecord.language);
      setId(props.currentRecord.id);
    }
  }, [props]);

  const handleClose = () => {
    setQuestion("");
    setQuestionErr("");
    setAnswer("");
    setAnswerErr("");
    setLanguageErr("");
    props.setUpdateShow(false);
  };
  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "language") {
      setLanguage(value);
      const err = InputValid(name, value);
      setLanguageErr(err);
    }
    if (name === "question") {
      setQuestion(value);
      const err = InputValid(name, value);
      setQuestionErr(err);
    }
    if (name === "answer") {
      setAnswer(value);
      const err = InputValid(name, value);
      setAnswerErr(err);
    }
  };

  const onsubmit = async (e) => {
    const checkLanguage = InputValid("language", language);
    if (checkLanguage) {
      setLanguageErr(checkLanguage);
      return false;
    }

    const checkQuestion = InputValid("question", question);
    if (checkQuestion) {
      setQuestionErr(checkQuestion);
      return false;
    }
    const checkAnswer = InputValid("answer", answer);
    if (checkAnswer) {
      setAnswerErr(checkAnswer);
      return false;
    }

    const data = {
      question,
      answer,
      language,
      id,
    };
    const jwtToken = localStorage.getItem("jwtToken");
    const result = await updateFaq(data, jwtToken);
    // updateFaq(data).then((result) => {
    if (result.status) {
      toast.dismiss();
      toast.success(result.message);
      handleClose();
      props.getData();
    } else {
      toast.dismiss();
      toast.error(result.message);
    }
    // });
  };

  return (
    <>
      <Modal show={props.updateShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>Update FAQ</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label> Language </Form.Label>
              <Form.Control
                name="language"
                onChange={handlechange}
                as="select"
                value={language}
              >
                <option value="">Select Language</option>
                <option value="English">English</option>
                <option value="Korean">Korean</option>
              </Form.Control>
              <span style={{ color: "red" }}>{languageErr}</span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> Question </Form.Label>
              <Form.Control
                name="question"
                onChange={handlechange}
                type="text"
                value={question}
              ></Form.Control>
              <span style={{ color: "red" }}>{questionErr}</span>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Answer</Form.Label>
              <Form.Control
                name="answer"
                onChange={handlechange}
                as="textarea"
                rows={3}
                value={answer}
              />
              <span style={{ color: "red" }}>{answerErr}</span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onsubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
