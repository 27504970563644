import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { addPartner } from "../../services/partners";
import { InputValid } from "../../validations/InputValid";
import { ImageValid } from "../validations/ImageValid";

export const AddPartnerModal = (props) => {
  const [heading, setheading] = useState("");
  const [headingErr, setheadingErr] = useState("");
  const [partner_image, setpartner_image] = useState("");
  const [partner_imageErr, setpartner_imageErr] = useState(false);
  const [language, setLanguage] = useState("");
  const [languageErr, setLanguageErr] = useState("");
  const [imageURL, setImageUrl] = useState("");

  const handleClose = () => {
    setheadingErr("");
    setheading("");
    setLanguage("")
    setLanguageErr("");
    setImageUrl("")
    props.setShow(false);
  };
  const handlechange = (e) => {
    let { name, value } = e.target;
    console.log(name, "value");
    if (name === "language") {
      setLanguage(value);
      const err = InputValid(name, value);
      setLanguageErr(err);
    }


    if (name == "heading") {
      setheading(value);
      const err = InputValid(name, value);
      setheadingErr(err);
    }
 
  };
  const handlechangeimage = (e) => {
    let { name } = e.target;

    const image = e.target.files[0];

    if (name === "partner_image") {
      setpartner_image(image);
      setImageUrl(URL.createObjectURL(image));
      let checkImage = ImageValid(name, image);
      setpartner_imageErr(checkImage);
    }
  };

  const onsubmit = async (e) => {
    const checkLanguage = InputValid("language", language);
    if (checkLanguage) {
      setLanguageErr(checkLanguage);
      return false;
    }

  
    const checkheading = InputValid("heading", heading);
    if (checkheading) {
      setheadingErr(checkheading);
      return false;
    }
    // console.log(	partner_image,"	partner_image");
    let checkImage = ImageValid("partner_image", partner_image);

    if (checkImage) {
      setpartner_imageErr(checkImage);
      return false;
    }
  

    const formdata = new FormData();

    formdata.append("heading", heading);


    formdata.append("partner_image", partner_image);
    formdata.append("language", language);

    const jwtToken = localStorage.getItem("jwtToken");
    const result = await addPartner(formdata, jwtToken);

    if (result.status) {
      toast.dismiss();
      toast.success(result.message);
      handleClose();
      props.getData();
    } else {
      toast.dismiss();
      toast.error(result.message);
    }
  };
  return (
    <>
      {" "}
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>Add Partner</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label> Language </Form.Label>
              <Form.Control
                name="language"
                onChange={handlechange}
                as="select"
                value={language}
              >
                <option value="">Select Language</option>
                <option value="English">English</option>
                <option value="Korean">Korean</option>
              </Form.Control>
              <span style={{ color: "red" }}>{languageErr}</span>
            </Form.Group>
       
            <Form.Group className="mb-3">
              <Form.Label> Heading </Form.Label>
              <Form.Control
                name="heading"
                onChange={handlechange}
                type="text"
                value={heading}
              ></Form.Control>
              <span style={{ color: "red" }}>{headingErr}</span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label> Partner image </Form.Label>
              <Form.Control
                name="partner_image"
                onChange={handlechangeimage}
                type="file"
                // value={heading}
              ></Form.Control>
              <span style={{ color: "red" }}>{partner_imageErr}</span>
            </Form.Group>
            <img
              style={{ width: "100px" }}
              src={imageURL}
              className="img-fluid"
              alt=""
            />
         
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onsubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
