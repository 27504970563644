import React, { useEffect, useState } from "react";
import Header from "../widget/Header";
import Navbar from "../widget/Navbar";
// import Sidebar from "./Sidebar";
import moment from "moment";
import Footer from "../widget/Footer";
import ReactDatatable from "@mkikets/react-datatable";
import { ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { getPartnerData, deletePartner } from "../services/partners";
import { AddPartnerModal } from "./partnerPartials/AddPartnerModal";
import { UpdatePartnerModal } from "./partnerPartials/UpdatePartnerModal";
export const Partner = () => {
  const [record, setRecord] = useState([]);
  const [show, setShow] = useState(false);
  const [updateShow, setUpdateShow] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  const columns = [
    {
      key: "Sr No.",
      text: "Sr. No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "language",
      text: "Language",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },
    {
      key: "heading",
      text: "Heading",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },

    
    {
      key: "partner_image",
      text: "Partner Image",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <img src={record.partner_image} />
          </>
        );
      },
    },

    {
      key: "created_at",
      text: "Register Date",
      className: "color",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{moment(record.update_at).format("LLL")}</>;
      },
    },
    {
      key: "action",
      text: "Action",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <button
              className="btn btn-primary"
              onClick={() => deleteAlert(record.id)}
              title="Delete"
              style={{ marginRight: "5px" }}
            >
              <span className="mdi mdi-trash-can-outline"></span>
            </button>
            <button
              onClick={() => edit(record)}
              title="Update"
              className="btn btn-primary"
            >
              <span className="mdi mdi-square-edit-outline"></span>
            </button>
          </>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Download",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Search in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const jwtToken = localStorage.getItem("jwtToken");
    const result = await getPartnerData(jwtToken);
    if (result.status) {
      setRecord(result.data);
    } else {
      toast.dismiss();
      toast.error(result.message);
    }
  };
  const deleteAlert = (Deleteid) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => partnerDelete(Deleteid),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const partnerDelete = async (id) => {
    let data = {
      id,
    };
    const jwtToken = localStorage.getItem("jwtToken");
    const result = await deletePartner(data, jwtToken);

    if (result.status) {
      toast.dismiss();
      toast.success(result.message);
      getData();
    } else {
      toast.dismiss();
      toast.error(result.message);
    }
  };

  const handleShow = (id) => {
    setShow(true);
  };

  const edit = (record) => {
    setUpdateShow(true);
    setCurrentRecord(record);
  };

  const pageChange = (pageData) => {};
  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />

        <div className="main-content">
          <div className="page-content">
            {" "}
            <div className="d-flex justify-content-center"></div>
            <div className="section-heading d-flex justify-content-between">
              <h2>
                <b>Partner List</b>
              </h2>
              <div className="add-product-btn ">
                <button
                  className="btn btn-primary"
                  onClick={() => handleShow()}
                >
                  Add Partner
                </button>
              </div>
            </div>
            <div className="product-list-outer table-responsive">
              <ReactDatatable
                config={config}
                records={record}
                columns={columns}
                onPageChange={pageChange}
              />
            </div>
          </div>
          <Footer />
          <ToastContainer
            limit={1}
            autoClose={3000}
            pauseOnHover={false}
            pauseOnFocusLoss={false}
            theme={"dark"}
          />
        </div>
        <AddPartnerModal show={show} setShow={setShow} getData={getData} />
        <UpdatePartnerModal
          updateShow={updateShow}
          setUpdateShow={setUpdateShow}
          currentRecord={currentRecord}
          getData={getData}
        />
      </div>
    </>
  );
};
