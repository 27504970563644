import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { addNews } from "../../services/news";
import { InputValid } from "../../validations/InputValid";
import { ImageValid } from "../validations/ImageValid";
import { updateNews } from "../../services/news";
export const UpdateNewsModal = (props) => {
  const [main_heading, setmain_heading] = useState("");
  const [main_headingErr, setmain_headingErr] = useState("");
  const [description, setdescription] = useState("");
  const [descriptionErr, setdescriptionErr] = useState("");
  const [link_url, setlink_url] = useState("");


  const [link_urlErr, setlink_urlErr] = useState("");

  const [heading, setheading] = useState("");
  const [headingErr, setheadingErr] = useState("");
  const [news_image, setnews_image] = useState("");
  const [news_imageErr, setnews_imageErr] = useState(false);
  const [language, setLanguage] = useState("");
  const [languageErr, setLanguageErr] = useState("");
  const [imageURL, setImageUrl] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    if (props.currentRecord) {
      setmain_heading(props.currentRecord.main_heading);
      setdescription(props.currentRecord.description);
      setImageUrl(props.currentRecord.news_image);

      setheading(props.currentRecord.heading);

      setLanguage(props.currentRecord.language);
      setlink_url(props.currentRecord.link_url);

      setId(props.currentRecord.id);
    }
  }, [props]);



  const handleClose = () => {
    setmain_heading("");
    setdescriptionErr("");
    setdescription("");
    setheadingErr("");
    setheading("");
    setmain_headingErr("");
    setLanguageErr("");
    props.setUpdateShow(false);
  };
  const handlechange = (e) => {
    let { name, value } = e.target;
    console.log(name, "value");
    if (name === "language") {
      setLanguage(value);
      const err = InputValid(name, value);
      setLanguageErr(err);
    }

    if (name === "main_heading") {
      setmain_heading(value);
      const err = InputValid(name, value);
      setmain_headingErr(err);
    }
    console.log(name, value);

    if (name == "heading") {
      setheading(value);
      const err = InputValid(name, value);
      setheadingErr(err);
    }
    if (name === "description") {
      setdescription(value);
      const err = InputValid(name, value);
      setdescriptionErr(err);
    }
    if (name === "link_url") {
      setlink_url(value);
      const err = InputValid(name, value);
      setlink_urlErr(err);
    }
  };
  const handlechangeimage = (e) => {
    let { name } = e.target;

    const image = e.target.files[0];
 
    if (name === "news_image") {
      setnews_image(image);
      setImageUrl(URL.createObjectURL(image));
      let checkImage = ImageValid(name, image);
      setnews_imageErr(checkImage);
    }
  };

  const onsubmit = async (e) => {
    const checkLanguage = InputValid("language", language);
    if (checkLanguage) {
      setLanguageErr(checkLanguage);
      return false;
    }

    const checkmain_heading = InputValid("main_heading", main_heading);
    if (checkmain_heading) {
      setmain_headingErr(checkmain_heading);
      return false;
    }
    const checkheading = InputValid("heading", heading);
    if (checkheading) {
      setheadingErr(checkheading);
      return false;
    }
// console.log(news_image,"news_image");
    let checkImage = ImageValid("news_image", news_image);

    if (checkImage) {
      setnews_imageErr(checkImage);
      return false;
    }
    const checkDescription = InputValid("description", description);
    if (checkDescription) {
      setdescriptionErr(checkDescription);
      return false;
    }
    const checklinkUrl = InputValid("link_url", link_url);
    if (checklinkUrl) {
      setlink_urlErr(checklinkUrl);
      return false;
    }
console.log(link_url,"link_url");
    const formdata = new FormData();
    formdata.append("id", id);
    formdata.append("main_heading", main_heading);
    formdata.append("heading", heading);
    formdata.append("description", description);

    formdata.append("news_image", news_image);
    formdata.append("language", language);
    formdata.append("link_url", link_url);
    const jwtToken = localStorage.getItem("jwtToken");
    const result = await updateNews(formdata, jwtToken);

    if (result.status) {
      toast.dismiss();
      toast.success(result.message);
      handleClose();
      props.getData();
    } else {
      toast.dismiss();
      toast.error(result.message);
    }
  };


  return <>
       <Modal show={props.updateShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>Update News</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label> Language </Form.Label>
              <Form.Control
                name="language"
                onChange={handlechange}
                as="select"
                value={language}
              >
                <option value="">Select Language</option>
                <option value="English">English</option>
                <option value="Korean">Korean</option>
              </Form.Control>
              <span style={{ color: "red" }}>{languageErr}</span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label> Main Heading </Form.Label>
              <Form.Control
                name="main_heading"
                onChange={handlechange}
                type="text"
                value={main_heading}
              ></Form.Control>
              <span style={{ color: "red" }}>{main_headingErr}</span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label> Heading </Form.Label>
              <Form.Control
                name="heading"
                onChange={handlechange}
                type="text"
                value={heading}
              ></Form.Control>
              <span style={{ color: "red" }}>{headingErr}</span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label> News image </Form.Label>
              <Form.Control
                name="news_image"
                onChange={handlechangeimage}
                type="file"
                // value={heading}
              ></Form.Control>
              <span style={{ color: "red" }}>{news_imageErr}</span>
            </Form.Group>
            <img
                style={{ width: "100px" }}
                src={imageURL}
                className="img-fluid"
                alt=""
              />
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label> description</Form.Label>
              <Form.Control
                name="description"
                onChange={handlechange}
                as="textarea"
                rows={3}
                value={description}
              />
              <span style={{ color: "red" }}>{descriptionErr}</span>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label> Link Url</Form.Label>
              <Form.Control
                name="link_url"
                onChange={handlechange}
                type="text"
                value={link_url}
              />
              <span style={{ color: "red" }}>{link_urlErr}</span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onsubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
  </>;
};
