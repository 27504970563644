import * as opsService from "./Ops";
import {
  addPartnerApi,
  getPartnerApi,
  deletePartnerApi,
  updatePartnerApi,
} from "../Constent/Api";

const addPartner = async (data, token) => {
  let result = await opsService.postdata(addPartnerApi, data, token);
  return result;
};
const getPartnerData = async (token) => {
  let result = await opsService.getData(getPartnerApi, token);
  return result;
};

const deletePartner = async (data, token) => {
  let result = await opsService.postdata(deletePartnerApi, data, token);
  return result;
};
const updatePartner = async (data, token) => {
  let result = await opsService.postdata(updatePartnerApi, data, token);
  return result;
};

export { addPartner, getPartnerData, deletePartner, updatePartner };
