import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { updateContent } from "../../services/content";
import { InputValid } from "../../validations/InputValid";

export const UpdateContentModal = (props) => {
  const [heading, setheading] = useState("");
  const [headingErr, setheadingErr] = useState("");
  const [paragraph, setparagraph] = useState("");
  const [paragraphErr, setparagraphErr] = useState("");
  const [language, setLanguage] = useState("");
  const [languageErr, setLanguageErr] = useState("");
  const [id, setId] = useState("");
  const handleClose = () => {
    setheading("");
    setheadingErr("");
    setLanguageErr("");
    setparagraphErr("");
    setparagraph("");
    props.setUpdateShow(false);
  };

  useEffect(() => {
    if (props.currentRecord) {
      setheading(props.currentRecord.heading);
      setparagraph(props.currentRecord.paragraph);
      setLanguage(props.currentRecord.language);

      setId(props.currentRecord.id);
    }
  }, [props]);

  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "language") {
      setLanguage(value);
      const err = InputValid(name, value);
      setLanguageErr(err);
    }

    if (name === "heading") {
      setheading(value);
      const err = InputValid(name, value);
      setheadingErr(err);
    }
    if (name === "paragraph") {
      setparagraph(value);
      const err = InputValid(name, value);
      setparagraphErr(err);
    }
  };

  const onsubmit = async (e) => {
    const checkLanguage = InputValid("language", language);
    if (checkLanguage) {
      setLanguageErr(checkLanguage);
      return false;
    }

    const checkheading = InputValid("heading", heading);
    if (checkheading) {
      setheadingErr(checkheading);
      return false;
    }
    const checkparagraph = InputValid("paragraph", paragraph);
    if (checkparagraph) {
      setparagraphErr(checkparagraph);
      return false;
    }

    const data = {
      heading,
      paragraph,
      language,
      id,
    };
    const jwtToken = localStorage.getItem("jwtToken");
    const result = await updateContent(data, jwtToken);

    if (result.status) {
      toast.dismiss();
      toast.success(result.message);
      handleClose();
      props.getData();
    } else {
      toast.dismiss();
      toast.error(result.message);
    }
  };
  return (
    <>
      {" "}
      <Modal show={props.updateShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>Update Content</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label> Language </Form.Label>
              <Form.Control
                name="language"
                onChange={handlechange}
                as="select"
                value={language}
              >
                <option value="">Select Language</option>
                <option value="English">English</option>
                <option value="Korean">Korean</option>
              </Form.Control>
              <span style={{ color: "red" }}>{languageErr}</span>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label> heading</Form.Label>
              <Form.Control
                name="heading"
                onChange={handlechange}
                type="text"
                value={heading}
              />
              <span style={{ color: "red" }}>{headingErr}</span>
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label> paragraph</Form.Label>
              <Form.Control
                name="paragraph"
                onChange={handlechange}
                as="textarea"
                rows={3}
                value={paragraph}
              />
              <span style={{ color: "red" }}>{paragraphErr}</span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onsubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
