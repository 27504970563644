import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { updateAboutUs } from "../../services/aboutUs";
import { InputValid } from "../../validations/InputValid";

export const UpdateAboutUsModal = (props) => {
  const [description, setdescription] = useState("");
  const [descriptionErr, setdescriptionErr] = useState("");
  const [language, setLanguage] = useState("");
  const [languageErr, setLanguageErr] = useState("");
  const [id, setId] = useState("");
  
  const handleClose = () => {
    setdescription("");
    setdescriptionErr("");
    setLanguageErr("");
    props.setUpdateShow(false);
  };

  useEffect(() => {
    if (props.currentRecord) {
      setdescription(props.currentRecord.description);
      setLanguage(props.currentRecord.language);

      setId(props.currentRecord.id);
    }
  }, [props]);

  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "language") {
      setLanguage(value);
      const err = InputValid(name, value);
      setLanguageErr(err);
    }

    if (name === "description") {
      setdescription(value);
      const err = InputValid(name, value);
      setdescriptionErr(err);
    }
  };

  const onsubmit = async (e) => {
    const checkLanguage = InputValid("language", language);
    if (checkLanguage) {
      setLanguageErr(checkLanguage);
      return false;
    }

    const checkdescription = InputValid("description", description);
    if (checkdescription) {
      setdescriptionErr(checkdescription);
      return false;
    }

    const data = {
      description,
      language,
      id,
    };
    const jwtToken = localStorage.getItem("jwtToken");
    const result = await updateAboutUs(data, jwtToken);

    if (result.status) {
      toast.dismiss();
      toast.success(result.message);
      handleClose();
      props.getData();
    } else {
      toast.dismiss();
      toast.error(result.message);
    }
  };
  return (
    <>
      <Modal show={props.updateShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>Update About Us</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label> Language </Form.Label>
              <Form.Control
                name="language"
                onChange={handlechange}
                as="select"
                value={language}
              >
                <option value="">Select Language</option>
                <option value="English">English</option>
                <option value="Korean">Korean</option>
              </Form.Control>
              <span style={{ color: "red" }}>{languageErr}</span>
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label> description</Form.Label>
              <Form.Control
                name="description"
                onChange={handlechange}
                as="textarea"
                rows={3}
                value={description}
              />
              <span style={{ color: "red" }}>{descriptionErr}</span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onsubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
