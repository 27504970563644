import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import "react-confirm-alert/src/react-confirm-alert.css";

import { ToastContainer } from "react-toastify";
import Dashboard from "./components/Dashboard";
import { UserManagement } from "./components/UserManagement";
import { AboutUs } from "./components/AboutUs";
import { News } from "./components/News";
import { useAuth } from "./AuthContext";
import ProtectedRoute from "./ProtectedRoute";
import { Faq } from "./components/Faq";
import { Partner } from "./components/Partner";
import { Content } from "./components/Content";
function App() {
  const { login } = useAuth();

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtToken");
    if (jwtToken) {
      login();
    }
  }, [login]);

  return (
    <>
      {/* <BaseUrl /> */}
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={<ProtectedRoute component={<Dashboard />} />}
          />
          <Route
            path="/user-management"
            element={<ProtectedRoute component={<UserManagement />} />}
          />
          <Route path="/faq" element={<ProtectedRoute component={<Faq />} />} />
          <Route
            path="/news"
            element={<ProtectedRoute component={<News />} />}
          />
          <Route
            path="/about-us"
            element={<ProtectedRoute component={<AboutUs />} />}
          />
           <Route
            path="/partner"
            element={<ProtectedRoute component={<Partner />} />}
          />
           <Route
            path="/content"
            element={<ProtectedRoute component={<Content />} />}
          />
          
        </Routes>
      </Router>
      <ToastContainer
        limit={1}
        autoClose={2000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        theme={"dark"}
      />
    </>
  );
}
export default App;
