import * as opsService from "./Ops";
import {
  addNewsApi,
  getNewsApi,
  deleteNewsApi,
  updateNewsApi,
} from "../Constent/Api";

const addNews = async (data, token) => {
  let result = await opsService.postdata(addNewsApi, data, token);
  return result;
};
const getNewsData = async (token) => {
  let result = await opsService.getData(getNewsApi, token);
  return result;
};

const deleteNews = async (data, token) => {
  let result = await opsService.postdata(deleteNewsApi, data, token);
  return result;
};
const updateNews = async (data, token) => {
  let result = await opsService.postdata(updateNewsApi, data, token);
  return result;
};

export { addNews, getNewsData, deleteNews, updateNews };