import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Header from "../widget/Header";
import Navbar from "../widget/Navbar";
import Footer from "../widget/Footer";
import { toast } from "react-toastify";
import ReactDatatable from "@mkikets/react-datatable";
import { ToastContainer } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import { getUserData, updateStatus } from "../services/user";
import moment from "moment";
import { InputValid } from "../validations/InputValid";

export const UserManagement = () => {
  const [record, setRecord] = useState([]);

  const [status, setStatus] = useState("");

  const columns = [
    {
      key: "Sr No.",
      text: "Sr. No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Name",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },

    {
      key: "country_code",
      text: "Country code",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },

    {
      key: "number",
      text: "Mobile Number",
      className: "sr_no.",
      align: "left",
      sortable: true,
    },

    {
      key: "company_name",
      text: "Company Name",
      className: "cust_name",
      align: "left",
      sortable: true,
    },
    {
      key: "address",
      text: "Address",
      className: "cust_name",
      align: "left",
      sortable: true,
    },
    {
      key: "pincode",
      text: "Pincode",
      className: "cust_name",
      align: "left",
      sortable: true,
    },
    {
      key: "document",
      text: "Document",
      className: "cust_name",
      align: "left",
      sortable: true,
      cell: (record) => {
        const isPDF = record.document.toLowerCase().endsWith(".pdf");
        return (
          <>
            {isPDF ? (
              <a
                href={record.document}
                className="url"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn btn-primary"  style={{ fontSize: "12px", cursor: "pointer" }}>Download Pdf </button>
                {/* i
                  className="fas fa-file-pdf"
                  style={{ fontSize: "48px", cursor: "pointer" }}
                >Download Pdf</i> */}
              </a>
            ) : (
              <img src={record.document} />
            )}
          </>
        );
      },
    },

    {
      key: "status",
      text: "Status",
      className: "Action ",
      align: "left",
      sortable: true,

      cell: (record) => {
        return record.status == "Pending" ? (
          <>
            <i
              onClick={() => confirmAction("Verified", record.id)}
              className="fa fa-check"
              title="approve"
              style={{
                color: "#fff",
                fontSize: "20px",
                cursor: "pointer",
                backgroundColor: "green",
              }}
            ></i>
            <i
              onClick={() => confirmAction("Reject", record.id)}
              className="fa fa-times ms-3"
              title="reject"
              style={{
                color: "#fff",
                fontSize: "20px",
                cursor: "pointer",
                backgroundColor: "red",
              }}
            ></i>
          </>
        ) : (
          <h6
            className="m-0"
            style={{
              backgroundColor: record.status == "Verified" ? "green" : "red",
              color: "#fff",

              textAlign: "center",
              borderRadius: "5px",

              padding: "6px",
            }}
          >
            {record.status}
          </h6>
        );
      },
    },
    {
      key: "registerDate",
      text: "Register Date",
      className: "color",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{moment(record.created_at).format("LLL")}</>;
      },
    },
  ];
  const statusHandler = async (type, id) => {
    const config = localStorage.getItem("jwtToken");
    let data = {
      status: type,
      id,
    };
    const res = await updateStatus(data, config);
    if (res.status) {
      toast.dismiss();
      toast.success(res.message);
      userDatas();
      // getIdo();
    } else {
      toast.dismiss();
      toast.success(res.message);
    }
  };
  const confirmAction = (type, record) => {
    const message =
      type === "Verified"
        ? "Really want to approve ?"
        : "Really want to reject ?";
    confirmAlert({
      title: "Confirm to submit",
      message: message,
      buttons: [
        {
          label: "Yes",
          onClick: () => statusHandler(type, record),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Download",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Search in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {};

  const userDatas = async () => {
    let config = localStorage.getItem("jwtToken");

    const resp = await getUserData(config);

    if (resp.status) {
      setRecord(resp?.data);
    }
  };
  useEffect(() => {
    userDatas();
  }, []);

  // const handleClose = () => {
  //   setbonus("");
  //   setBonusErr("");
  //   setUsdt("");

  //   setShow(false);
  // };
  const updateAlert = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => UpdateStatus(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const UpdateStatus = async (id) => {
    let data = {
      id,
      status,
    };
    const resp = await updateStatus(data);
    if (resp.data.status) {
      toast.dismiss();
      toast.success(resp.data.message);
    }
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />

      <div className="main-content">
        <div className="page-content">
          {" "}
          <div className="d-flex justify-content-center"></div>
          <div className="section-heading d-flex justify-content-between">
            <h2>
              <b>User List</b>
            </h2>
          </div>
          <div className="product-list-outer table-responsive">
            <ReactDatatable
              config={config}
              records={record}
              columns={columns}
              onPageChange={pageChange}
              
            />
          </div>
        </div>
        <Footer />
        <ToastContainer
          limit={1}
          autoClose={3000}
          pauseOnHover={false}
          pauseOnFocusLoss={false}
          theme={"dark"}
        />
      </div>
    </div>
  );
};
