import * as opsService from "./Ops";
import {
  addFaqApi,
  getFaqApi,
  deleteFaqApi,
  updateFaqApi,
} from "../Constent/Api";

const addFaq = async (data, token) => {
  let result = await opsService.postdata(addFaqApi, data, token);
  return result;
};
const getFaqData = async (token) => {
  let result = await opsService.getData(getFaqApi, token);
  return result;
};

const deleteFaq = async (data, token) => {
  let result = await opsService.postdata(deleteFaqApi, data, token);
  return result;
};
const updateFaq = async (data, token) => {
  let result = await opsService.postdata(
    updateFaqApi,
    data,
    token
  );
  return result;
};

export { addFaq, getFaqData, deleteFaq,updateFaq };
