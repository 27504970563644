import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function Sidebar() {
  useEffect(() => {}, []);

  return (
    <div id="scrollbar">
      <div className="simplebar-content" style={{ padding: "0px" }}>
        <div className="container-fluid p-0">
          <ul className="navbar-nav" id="navbar-nav">
            <li className="nav-item">
              <Link
                to={"/dashboard"}
                className={
                  window.location.pathname === "/dashboard"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="mdi mdi-speedometer" />
                <span data-key="t-dashboards">Dashboard</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/user-management"}
                className={
                  window.location.pathname === "/user-management"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="las la-users"></i>
                <span data-key="t-dashboards">User-Management</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/content"}
                className={
                  window.location.pathname === "/content"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="las la-users"></i>
                <span data-key="t-dashboards">Content</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/faq"}
                className={
                  window.location.pathname === "/faq"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="las la-users"></i>
                <span data-key="t-dashboards">FAQ</span>
              </Link>
            </li>
           
            <li className="nav-item">
              <Link
                to={"/news"}
                className={
                  window.location.pathname === "/news"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="las la-users"></i>
                <span data-key="t-dashboards">News</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/partner"}
                className={
                  window.location.pathname === "/partner"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="las la-users"></i>
                <span data-key="t-dashboards">Partner</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/about-us"}
                className={
                  window.location.pathname === "/about-us"
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <i className="las la-users"></i>
                <span data-key="t-dashboards">About Us</span>
              </Link>
            </li>
            
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Sidebar;
